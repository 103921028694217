import 'react-datepicker/dist/react-datepicker.css';

import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import dropoffIcon from '../../../assets/icons/dropoffs.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { useModal } from '../../../hooks/useModal';
import { createModalStateAtom } from '../../../recoil/modalConfirm';
import { successModalStateAtom } from '../../../recoil/modalSuccess';
import { needRefreshState } from '../../../recoil/needRefresh';
import { userStateAtom } from '../../../recoil/userData';
import { CREATE_DROPOFF } from '../../../services/graphql/vendors/dropoffs/dropoffsMutation';
import { GET_DROPOFF_OPTIONS } from '../../../services/graphql/vendors/dropoffs/dropoffsQuery';
import type { GetDropoffOptionsResponse } from '../../../types/dropoffs';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Inputs/Dropdown';
import InputNumber from '../../ui/common/Inputs/InputNumber';
import Toggle from '../../ui/common/Toggle';

type Inputs = {
  vendor_id?: string; // Form
  driver_id?: string; // Form
  truck_id?: string; // Form
  location_id?: string; // Form
  total_gallons?: number; // Form + calculated
  total_pounds?: number; // Form + calculated
  container_volume?: number; // Calculated
  container_volume_show?: number; // Calculated
  miu_percentage?: number; // Form
  ffa?: number; // Form
  sulfur?: number; // Form
  registered_by?: string; // Auto
  traceability?: boolean; // Form
  miu?: number; // Calculated
  net_ikg?: number; // Calculated
  measuring_method?: string; // Calculated
  driver_signature?: string; // Auto
};

const NewDropoff = () => {
  const { t } = useTranslation('common');
  const [units, setUnits] = useState<'Gal' | 'Lbs'>('Gal');
  const user = useRecoilValue(userStateAtom);
  const setCreateModal = useSetRecoilState(createModalStateAtom);
  const setSuccessModal = useSetRecoilState(successModalStateAtom);

  // Filters
  const {
    setValue,
    watch,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      traceability: false,
    },
  });

  const {
    vendor_id: vendorId,
    total_gallons: totalGallons,
    total_pounds: totalPounds,
    miu_percentage: miuPercentage,
    miu: miuTotal,
  } = watch();

  useEffect(() => {
    if (register) {
      register('vendor_id', { required: t('global.required') });
      register('location_id', { required: t('global.required') });
      register('driver_id', { required: t('global.required') });
      register('truck_id', { required: t('global.required') });
    }
  }, [register]);

  useEffect(() => {
    if (totalGallons && units === 'Gal')
      setValue('total_pounds', totalGallons * 7.5);
  }, [totalGallons]);

  useEffect(() => {
    if (totalPounds && units === 'Lbs')
      setValue('total_gallons', totalPounds / 7.5);
  }, [totalPounds]);

  useEffect(() => {
    setValue('driver_id', '');
    setValue('truck_id', '');
    setValue('container_volume', undefined);
    setValue('container_volume_show', undefined);
  }, [vendorId]);

  useEffect(() => {
    if (units === 'Gal' && totalGallons && miuPercentage)
      setValue('miu', totalGallons * 7.5 * (miuPercentage / 100));
    if (units === 'Lbs' && totalPounds && miuPercentage)
      setValue('miu', totalPounds * 1.35 * (miuPercentage / 100));
  }, [totalGallons, totalPounds, miuPercentage]);

  useEffect(() => {
    if (miuTotal && totalPounds) setValue('net_ikg', totalPounds - miuTotal);
  }, [miuTotal, totalPounds]);

  // === QUERIES ===
  const { data: options } = useQuery<GetDropoffOptionsResponse>(
    GET_DROPOFF_OPTIONS,
    {
      fetchPolicy: 'network-only',
      variables: { vendorId },
    },
  );

  // === MUTATIONS ===
  const [createDropoff, { loading }] = useMutation(CREATE_DROPOFF);

  // Form title
  const breadcrumbTitle = `${t('modalNewDropoff.breadcrumps-1')}`;
  const breadcrumbSecondTitle = t('modalNewDropoff.breadcrumps-2');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh('dropoffs');
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  const handleFiltersSave = (data: Inputs) => {
    setCreateModal({
      isOpen: true,
      message: t('modalNewDropoff.confirmation-modal-text'),
      onCancel: () => {},
      onConfirm: () => {
        createDropoff({
          variables: {
            ...data,
            registered_by: user.id,
          },
          onCompleted: () => {
            setCreateModal((prev) => ({ ...prev, isOpen: false }));
            setSuccessModal({
              message: t('modalNewDropoff.success-modal-text'),
              title: t('modalNewDropoff.success-modal-title'),
              isOpen: true,
            });
            closeOrder();
          },
        });
      },
      title: t('modalNewDropoff.confirmation-modal-title'),
    });
  };

  const onTruckSelect = (val: string) => {
    setValue('truck_id', val);
    const truck = options?.getDropoffOptions.data.truckOptions?.find(
      (tr) => tr.value === val,
    );
    setValue('container_volume', truck?.capacity);
    if (!truck) {
      setValue('container_volume_show', undefined);
      setValue('container_volume', undefined);
      return;
    }
    const container = Number(truck.capacity);
    if (units === 'Lbs') setValue('container_volume_show', container * 7.5);
    else setValue('container_volume_show', container);
    setValue('container_volume', container);
  };

  return (
    <div className="">
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={dropoffIcon}
      />
      <h1 className="text-xl font-[600]">{t('modalNewDropoff.title')}</h1>
      <form
        onSubmit={handleSubmit(handleFiltersSave)}
        className="my-6 flex flex-col gap-2"
      >
        {/* Vendor Dropdown */}
        <div className="h-[60px]">
          <Dropdown
            label={t('modalNewDropoff.vendor')}
            selectedValue={watch('vendor_id') || undefined}
            errors={errors.vendor_id}
            options={options?.getDropoffOptions.data.vendorOptions || []}
            onSelectChange={(val) => setValue('vendor_id', val)}
          />
        </div>
        {/* Driver Dropdown */}
        <div className="h-[60px]">
          <Dropdown
            label={t('modalNewDropoff.driver')}
            selectedValue={watch('driver_id') || undefined}
            errors={errors.driver_id}
            options={
              vendorId
                ? options?.getDropoffOptions.data.driverOptions || []
                : []
            }
            onSelectChange={(val) => setValue('driver_id', val)}
          />
        </div>
        {/* Location Dropdown */}
        <div className="h-[60px]">
          <Dropdown
            label={t('modalNewDropoff.location')}
            selectedValue={watch('location_id') || undefined}
            errors={errors.location_id}
            options={options?.getDropoffOptions.data.locationOptions || []}
            onSelectChange={(val) => {
              setValue('location_id', val);
              const location =
                options?.getDropoffOptions.data.locationOptions?.find(
                  (tr) => tr.value === val,
                );
              setValue('measuring_method', location?.measuring_method);
              if (location?.measuring_method === 'scale') setUnits('Lbs');
              else setUnits('Gal');
            }}
          />
        </div>
        {/* Truck Dropdown */}
        <div className="h-[60px]">
          <Dropdown
            label={t('modalNewDropoff.truck')}
            selectedValue={watch('truck_id') || 'test'}
            errors={errors.truck_id}
            options={
              vendorId ? options?.getDropoffOptions.data.truckOptions || [] : []
            }
            onSelectChange={onTruckSelect}
          />
        </div>
        {/* Gross IKG Input */}
        <InputNumber
          control={control}
          label={t('modalNewDropoff.gross-ikg')}
          suffix={` ${units}`}
          defaultValue=""
          registerName={units === 'Gal' ? 'total_gallons' : 'total_pounds'}
          errors={units === 'Gal' ? errors.total_gallons : errors.total_pounds}
          validations={{
            required: t('global.required'),
          }}
          decimalCount={0}
          labelClassname="!font-[600]"
        />

        {/* Container Volume Input */}
        <InputNumber
          control={control}
          label={t('modalNewDropoff.container-volume')}
          suffix={` ${units}`}
          disabled
          defaultValue=""
          registerName={'container_volume_show'}
          decimalCount={0}
          labelClassname="!font-[600]"
        />

        {/* MIU % Input */}
        <InputNumber
          control={control}
          label={t('modalNewDropoff.miu')}
          suffix="%"
          defaultValue=""
          registerName={'miu_percentage'}
          errors={errors.miu_percentage}
          validations={{
            required: t('global.required'),
          }}
          decimalCount={2}
          labelClassname="!font-[600]"
        />

        {/* FFA Input */}
        <InputNumber
          control={control}
          label={t('modalNewDropoff.ffa')}
          suffix=" ppm"
          defaultValue=""
          registerName={'ffa'}
          errors={errors.ffa}
          validations={{
            required: t('global.required'),
          }}
          decimalCount={0}
          labelClassname="!font-[600]"
        />

        {/* Sulfur Input */}
        <InputNumber
          control={control}
          label={t('modalNewDropoff.sulfur')}
          suffix=" ppm"
          defaultValue=""
          registerName={'sulfur'}
          errors={errors.sulfur}
          validations={{
            required: t('global.required'),
          }}
          decimalCount={0}
          labelClassname="!font-[600]"
        />

        {/* Ready To Visit */}
        <div className="h-[60px]">
          <Toggle
            label={t('modalNewDropoff.traceability')}
            selectedValue={watch('traceability') || false}
            onSelectChange={(val) => setValue('traceability', val)}
            className="flex flex-row items-center justify-between"
          />
        </div>

        <div className="mt-2 flex justify-end">
          <Button
            text={t('modalNewDropoff.save')}
            variant="green"
            icon={saveBigIcon}
            type="submit"
            id="save-dropoff-filters"
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default NewDropoff;
