import { useLazyQuery, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import type { TDropoffsFilters } from '@/recoil/dropoffFilters';
import type {
  Dropoff,
  DropoffQuery,
  SingleDropoffQuery,
} from '@/types/dropoffs';

import {
  DROPOFFS_MAX_RESULTS,
  MAX_NUMBER_OF_TIMELINES,
} from '../config/constants';
import {
  GET_DROPOFFS,
  GET_SINGLE_DROPOFF,
} from '../services/graphql/vendors/dropoffs/dropoffsQuery';

const useDropoffList = ({
  vendorId,
  ...filters
}: { vendorId?: string } & TDropoffsFilters) => {
  const [dropoffs, setDropoffs] = useState<Dropoff[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const {
    // data,
    fetchMore,
    loading,
    error: errorTimeline,
    refetch,
    updateQuery: updateQueryTimeline,
  } = useQuery<DropoffQuery>(GET_DROPOFFS, {
    variables: {
      vendorId,
      offset: 0,
      limit: DROPOFFS_MAX_RESULTS,
      ...filters,
      vendorIds: vendorId ? [vendorId] : filters.vendorIds,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res: DropoffQuery) => {
      const data = res.getDropoffs;
      setHasNextPage(data.count > data.data.length);
      setDropoffs(data.data || []);
    },
  });

  const [fetchSingleQuery, { loading: loadingSingleQuery }] =
    useLazyQuery<SingleDropoffQuery>(GET_SINGLE_DROPOFF, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

  function calculateHasNextPage(total: number, currentTotal: number) {
    return currentTotal < total;
  }

  function refetchDropoffs(type?: string | undefined | null) {
    setHasNextPage(true);
    setDropoffs([]);

    let filter: string[] | undefined;

    if (type !== null && type !== undefined) {
      if (type !== 'All') {
        filter = [type];
      }
    }

    refetch({
      vendorId,
      offset: 0,
      limit: DROPOFFS_MAX_RESULTS,
      type: filter,
      ...filters,
      vendorIds: vendorId ? [vendorId] : filters.vendorIds,
    });
  }

  const fetchMoreDropoffs = useCallback(() => {
    if (dropoffs.length === MAX_NUMBER_OF_TIMELINES) return;

    fetchMore({
      variables: {
        ...filters,
        vendorId,
        vendorIds: vendorId ? [vendorId] : filters.vendorIds,
        offset: dropoffs.length,
        limit: DROPOFFS_MAX_RESULTS,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newData = fetchMoreResult.getDropoffs.data;
        const { count } = fetchMoreResult.getDropoffs;

        const newDropoffs = [...dropoffs, ...newData].reduce(
          (p: Dropoff[], c: Dropoff) => {
            // if the next object's id is not found in the output array
            // push the object into the output array
            if (
              !p.some((el) => {
                return el.id === c.id;
              })
            )
              p.push(c);
            return p;
          },
          [],
        );
        setDropoffs(newDropoffs);

        // // Verificar si hay más páginas después de la página actual
        setHasNextPage(calculateHasNextPage(count, newDropoffs.length));

        const prevValue = prev.getDropoffs || [];

        const newValue = {
          getDropoffs: {
            ...prevValue,
            data: [...(prevValue.data || []), ...newData],
          },
        };

        return newValue;
      },
    });
  }, [fetchMore, dropoffs, vendorId]);

  const refetchSingleDropoff = (id: string) => {
    fetchSingleQuery({
      variables: { id },
      onCompleted: (res) => {
        const i = dropoffs.findIndex((d) => d.id === id);
        if (i > -1 && res.getVendorDropoff.data) {
          const newDropoffs = [...dropoffs];
          newDropoffs[i] = { ...res.getVendorDropoff.data };
          setDropoffs([...newDropoffs]);
        }
      },
    });
  };

  return {
    fetchMoreDropoffs,
    loading,
    dropoffs,
    refetchDropoffs,
    updateQueryTimeline,
    errorTimeline,
    hasNextPage,
    loadingSingleQuery,
    refetchSingleDropoff,
  };
};

export default useDropoffList;
