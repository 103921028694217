import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser(
    $name: String!
    $phone: String!
    $email: String!
    $role: String!
    $profile_picture: String
  ) {
    createUser(
      name: $name
      phone: $phone
      email: $email
      role: $role
      profile_picture: $profile_picture
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($key: String!, $value: String!, $userid: String!) {
    updateUser(key: $key, value: $value, userid: $userid) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_OWN_USER = gql`
  mutation UpdateOwnUser($key: String!, $value: String!, $userid: String!) {
    updateUser(key: $key, value: $value, userid: $userid) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_USERS = gql`
  mutation DeleteUser($userId: [String]!) {
    deleteUser(userId: $userId) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_SIGNATURE = gql`
  mutation UpdateSignOrProfile($file: Upload!, $type: String!, $id: String!) {
    updateSignOrProfile(file: $file, type: $type, id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $email: String!
    $auth0_id: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      email: $email
      auth0_id: $auth0_id
    )
  }
`;
