import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import callIcon from '../../assets/icons/call.svg';
import emailIcon from '../../assets/icons/email.svg';
import saveIcon from '../../assets/icons/save.svg';
import userIcon from '../../assets/icons/user.svg';
import nameIcon from '../../assets/icons/user-square.svg';
import workerIcon from '../../assets/icons/workers.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import InputField from '../../components/ui/common/Inputs/InputField';
import { Signature } from '../../components/ui/common/Signature';
import { useNotification } from '../../hooks/useNotification';
import { userStateAtom } from '../../recoil/userData';
import {
  CHANGE_PASSWORD,
  UPDATE_OWN_USER,
  UPDATE_SIGNATURE,
} from '../../services/graphql/user/user-mutations';
import { GET_USER_BY_ID } from '../../services/graphql/user/user-query';
import type { UserQuery } from '../../types/user';
import { PASSWORD_REGEX } from '../../utils/RegexValidation';

interface PasswordInputs {
  newPassword: string;
  confirmPassword: string;
  currentPassword: string;
}

interface NewPasswordFormProps {
  email?: string;
  auth0_id?: string;
}

const NewPasswordForm = ({ email, auth0_id }: NewPasswordFormProps) => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError, notifyWrongPassword } =
    useNotification();

  // === FORM ===
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<PasswordInputs>();
  const { confirmPassword, currentPassword, newPassword } = watch();

  // === MUTATIONS ===
  const [updatePassword] = useMutation<any>(CHANGE_PASSWORD, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: ({ changePassword }: any) => {
      if (!changePassword) {
        notifyWrongPassword();
      } else {
        notifySaveSuccess();
        reset({
          newPassword: '',
          confirmPassword: '',
          currentPassword: '',
        });
      }
    },
  });

  const onSubmit = (data: PasswordInputs) => {
    if (email && auth0_id) {
      if (data.confirmPassword !== data.newPassword) {
        setError('newPassword', {
          type: 'pattern',
          message: t('profile.passwords-must-match'),
        });
        return;
      }
      updatePassword({
        variables: {
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
          email,
          auth0_id,
        },
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-[300px] flex-col justify-between text-xl font-bold"
    >
      <p>{t('profile.change-password-title')}</p>
      {/* Current Password Input */}
      <div className="sm:w-[250px]">
        <InputField
          errors={errors.currentPassword}
          selectedValue={currentPassword}
          id="currentPassword"
          doNotUseSaveSelfButton
          placeholder="Password"
          type="password"
          label={t('profile.current-password')}
          register={register}
          registerName="currentPassword"
          validationSchema={{
            required: t('profile.required'),
          }}
        />
      </div>
      {/* New Password Input */}
      <div className="sm:w-[250px]">
        <InputField
          errors={errors.newPassword}
          selectedValue={newPassword}
          doNotUseSaveSelfButton
          placeholder="Password"
          id="newPassword"
          type="password"
          label={t('profile.new-password')}
          register={register}
          registerName="newPassword"
          validationSchema={{
            required: t('profile.required'),
            pattern: {
              value: PASSWORD_REGEX,
              message: t('profile.invalid-password'),
            },
          }}
        />
      </div>
      {/* Confirm Password Input */}
      <div className="sm:w-[250px]">
        <InputField
          errors={errors.confirmPassword}
          selectedValue={confirmPassword}
          doNotUseSaveSelfButton
          placeholder="Password"
          id="confirmPassword"
          type="password"
          label={t('profile.confirm-password')}
          register={register}
          registerName="confirmPassword"
          validationSchema={{
            required: t('profile.required'),
          }}
        />
      </div>
      <button
        type="submit"
        id="savePassword"
        className="button-icon-reverse inline-flex w-full items-center justify-center gap-2 rounded-[5px] border !bg-green p-2 text-white hover:border-transparent hover:!bg-gold hover:text-green"
      >
        <img className="h-[16px] text-white" src={saveIcon} />
        <p className="text-[14px]">{t('profile.save-new-password')}</p>
      </button>
    </form>
  );
};

const ProfileView = () => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [userData, setUserData] = useRecoilState(userStateAtom);

  const [signature, setSignature] = useState<File | null>(null);
  const [signatureImage, setSignatureImage] = useState<string | undefined>(
    userData?.signature,
  );

  const { refetch } = useQuery<UserQuery>(GET_USER_BY_ID, {
    variables: { id: userData?.id },
    onCompleted: (data) => {
      if (data?.getUser?.data) {
        setUserData({
          ...userData,
          name: data?.getUser?.data.name,
          phone: data?.getUser?.data.phone,
          email: data?.getUser?.data.email,
          signature: data?.getUser?.data.signature,
          updatedAt: data?.getUser?.data.updatedAt,
        });
      }
    },
  });

  useEffect(() => {
    if (userData && !signatureImage) setSignatureImage(userData.signature);
  }, [userData]);

  // === MUTATIONS ===
  const [updateUser] = useMutation<any>(UPDATE_OWN_USER, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      notifySaveSuccess();
      refetch();
    },
  });

  const [updateSignature] = useMutation<any>(UPDATE_SIGNATURE, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: async ({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      updateSignOrProfile: { data },
    }: {
      updateSignOrProfile: { data: string };
    }) => {
      notifySaveSuccess();
      setSignatureImage(data);
    },
  });

  // === FUNCTIONS ===
  const handleNameChange = (value: string | number) => {
    if (userData) {
      updateUser({
        variables: {
          userid: userData.id,
          key: 'name',
          value,
        },
      });
    }
  };

  const handleEmailChange = (value: string | number) => {
    if (userData) {
      updateUser({
        variables: {
          userid: userData.id,
          key: 'email',
          value,
        },
      });
    }
  };

  const handlePhoneChange = (value: string | number) => {
    if (userData) {
      updateUser({
        variables: {
          userid: userData.id,
          key: 'phone',
          value,
        },
      });
    }
  };

  const handleSignatureChange = async () => {
    if (userData) {
      setSignatureImage(undefined);
      updateSignature({
        variables: {
          file: signature,
          type: 'signature',
          id: userData.id,
        },
      });
    }
  };

  return (
    <>
      <section className="mb-5">
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={t('profile.profile-h1')}
            image={userIcon}
            // secondTitle={userData?.name}
          />
        </div>
        <div className="mt-10 flex flex-col gap-y-5 md:flex-row md:flex-wrap md:gap-x-16">
          <div className="flex flex-col gap-y-3.5 text-xl font-bold sm:h-[300px]">
            <p>{t('profile.basic-info')}</p>
            <div className="box-shadow flex h-full flex-col items-center gap-2 rounded-lg px-6 py-10 md:flex-row md:py-[4.75rem]">
              <div className="rounded-full bg-greenlight p-5">
                <img
                  src={workerIcon}
                  alt="worker icon"
                  className="img-color w-[50px]"
                />
              </div>
              <div className="ml-5 flex flex-col gap-y-2">
                {/* Name Input */}
                <div className="w-[250px]">
                  <InputField
                    type="text"
                    id="name"
                    selectedValue={userData?.name}
                    onSelectChange={handleNameChange}
                    icon={nameIcon}
                  />
                </div>
                {/* Email Input */}
                <div className="w-[250px]">
                  <InputField
                    type="text"
                    id="email"
                    selectedValue={userData?.email}
                    onSelectChange={handleEmailChange}
                    icon={emailIcon}
                  />
                </div>
                {/* Phone Input */}
                <div className="w-[250px]">
                  <InputField
                    type="text"
                    id="phone"
                    selectedValue={userData?.phone}
                    onSelectChange={handlePhoneChange}
                    icon={callIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          <Signature
            handleSignatureChange={handleSignatureChange}
            setSignature={setSignature}
            signature={signatureImage}
            canEditSignature
          />
          <div>
            <NewPasswordForm
              email={userData?.email}
              auth0_id={userData?.auth0_id}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileView;
