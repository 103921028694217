/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-case */
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import iconAdd from '../../../../assets/icons/add-circle.svg';
import arrowdown from '../../../../assets/icons/arrow-down.svg';
import calendarIcon from '../../../../assets/icons/calendar.svg';
import gallonsIcon from '../../../../assets/icons/capacity.svg';
import locationIcon from '../../../../assets/icons/location.svg';
import truckIcon from '../../../../assets/icons/truck.svg';
import removeIcon from '../../../../assets/icons/trush-square.svg';
import decalIcon from '../../../../assets/icons/vehicle-decal.svg';
import userIcon from '../../../../assets/icons/workers.svg';
import { MEASURING_MEHTOD_OPTIONS } from '../../../../config/constants';
import { useNotification } from '../../../../hooks/useNotification';
import { confirmationModalState } from '../../../../recoil/modalDelete';
import { needRefreshState } from '../../../../recoil/needRefresh';
import {
  DELETE_DROPOFF,
  UPDATE_DROPOFF,
} from '../../../../services/graphql/vendors/dropoffs/dropoffsMutation';
import { CREATE_VENDOR_PAYMENT } from '../../../../services/graphql/vendors/payments/paymentsMutation';
import type {
  Dropoff,
  DropoffDropdownOptions,
} from '../../../../types/dropoffs';
import {
  convertDateToString,
  parseDateWithoutTimezone,
} from '../../../../utils/convertDate';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import { formatNumber } from '../../../../utils/numberFormatter';
import DropdownWithContent from '../../common/DropdownWithContent';
import Dropdown from '../../common/Inputs/Dropdown';
import InputCardField from '../../common/Inputs/InputCardField';
import Toggle from '../../common/Toggle';
import LoaderSmall from '../../LoaderSmall';
import DropoffPaymentCard from './DropoffPaymentCard';
import DropoffStatusDropdown from './DropoffStatusDropdown';
import DTDropoffsDocuments from './DTDropoffsDocuments';

type DropoffCardProps = {
  dropoff: Dropoff;
  vendorName: string;
  vendorId: string;
  refetchSingleDropoff: (id: string) => void;
  loadingSingleQuery: boolean;
  dropdownOptions?: DropoffDropdownOptions;
};

const IconTextElement = ({ icon, text }: { icon: string; text: string }) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-x-1">
      <img src={icon} alt="Arrow Down" width="22" height="22" />
      <p className="text-center text-sm font-semibold">{text || '-'}</p>
    </div>
  );
};

const DropoffCard: React.FC<DropoffCardProps> = ({
  dropoff,
  vendorName,
  refetchSingleDropoff,
  loadingSingleQuery,
  dropdownOptions,
}) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const setConfirmModalState = useSetRecoilState(confirmationModalState);
  const setNeedsRefresh = useSetRecoilState(needRefreshState);

  const toggleDropdown = () => setIsOpen(!isOpen);
  // const { t } = useTranslation('common');
  const { watch, setValue, reset } = useForm<Dropoff>({
    defaultValues: {
      ...dropoff,
    },
  });

  const d = watch();
  const { notifySaveError, notifySaveSuccess } = useNotification();

  const convertToString = (value: string | number | boolean): string => {
    if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    }

    return String(value);
  };

  const [createPayment] = useMutation(CREATE_VENDOR_PAYMENT);
  const [updateDropoff] = useMutation(UPDATE_DROPOFF);
  const [deleteDropoff] = useMutation(DELETE_DROPOFF);

  const handleUpdate = (
    key: keyof Dropoff,
    value: string | number | boolean,
  ) => {
    const currentValue = dropoff[key];

    if (value !== currentValue) {
      setLoading(true);
      setValue(key, value);
      updateDropoff({
        variables: {
          key,
          value: convertToString(value),
          id: dropoff.id,
        },
        onCompleted: () => {
          refetchSingleDropoff(dropoff.id);
          notifySaveSuccess();
        },
        onError: () => {
          notifySaveError();
        },
      });
    }
  };

  const handleNewPayment = () => {
    setLoading(true);
    createPayment({
      variables: {
        dropoffId: dropoff.id,
      },
      onCompleted: () => {
        refetchSingleDropoff(dropoff.id);
        notifySaveSuccess();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  useEffect(() => {
    const balance = dropoff.vendor_payments
      .filter((p) => p.paid_at || p.payment_type === 'Loan Credit')
      .reduce((acc, curr) => acc - curr.amount, dropoff.total_amount);
    reset({ ...dropoff, balance });
  }, [dropoff]);

  useEffect(() => {
    if (!loadingSingleQuery) setLoading(false);
  }, [loadingSingleQuery]);

  const handleDeleteDropoff = () => {
    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        deleteDropoff({
          variables: { id: dropoff.id },
          onCompleted: () => {
            setNeedsRefresh('dropoffs');
            setTimeout(() => {
              setNeedsRefresh(false);
            }, 500);
          },
        });
      },
      onCancel: () => {
        // Nothing happens
      },
      message: t('global.are-you-sure-delete'),
    });
  };

  return (
    <div className="flex w-full flex-col items-start justify-start gap-[15px] rounded-[10px] bg-white p-2.5 shadow-[0px_2px_12px_0px_rgba(0,0,0,0.20)]">
      <div className="mx-auto flex w-full flex-col gap-y-4 text-left">
        <div className="flex flex-row justify-between">
          <div className="flex w-full items-center text-base font-semibold text-zinc-800">
            {vendorName}
          </div>
          <div className="flex w-full flex-row items-center justify-end">
            <span className="min-w-[100px] text-sm">
              {convertToAmericanDateFormat(d.date_dropoff)}
            </span>
            <div className="flex items-center justify-end gap-[15px]">
              <DropoffStatusDropdown
                initialStatus={d.status}
                dropoffId={dropoff.id}
                refetchSingleDropoff={refetchSingleDropoff}
                setLoading={setLoading}
              />
            </div>
            <button
              onClick={toggleDropdown}
              type="button"
              className=" inline-flex h-7 items-center justify-between text-lg font-semibold"
              aria-expanded={isOpen}
            >
              <span
                className={`relative ml-2 h-5 w-5 ${
                  isOpen ? 'rotate-180' : ''
                }`}
              >
                <img src={arrowdown} alt="Arrow Down" width="22" height="22" />
              </span>
            </button>
          </div>
        </div>
        <div
          className={`w-full origin-top-right rounded-md ring-black ${
            loading ? 'block' : 'hidden'
          }`}
        >
          <div className="flex flex-row items-center justify-around">
            <LoaderSmall />
          </div>
        </div>
        {/* Content shown when closed */}
        <div
          className={`w-full origin-top-right rounded-md ring-black ${
            !isOpen && !loading ? 'block' : 'hidden'
          }`}
        >
          <div className="flex flex-row items-center justify-around">
            <IconTextElement
              icon={truckIcon}
              text={watch('vendor_truck.license_plate')}
            />
            <IconTextElement
              icon={locationIcon}
              text={watch('location.name')}
            />
            <IconTextElement
              icon={decalIcon}
              text={watch('vehicle_decal_number')}
            />
            <IconTextElement
              icon={gallonsIcon}
              text={`${formatNumber(watch('total_gallons') || 0)} Gal`}
            />
            <IconTextElement icon={userIcon} text={watch('User.name')} />
          </div>
        </div>
        {/* Content shown when opened */}
        <div
          className={`w-full origin-top-right flex-col gap-y-4 rounded-md ring-black ${
            isOpen && !loading ? 'flex' : 'hidden'
          }`}
        >
          <div className="grid w-full grid-cols-1 gap-x-5 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="flex items-center justify-between gap-2 border-b border-gray-400">
              <div className="truncate text-sm !font-normal text-gray-400">
                {t('vendor-dropoff-card.facility')}
              </div>
              <Dropdown
                options={dropdownOptions?.locationOptions || []}
                selectedValue={d.location_id || ''}
                placeholder={t('vendor-dropoff-card.facility')}
                onSelectChange={(newValue) => {
                  handleUpdate('location_id', newValue);
                }}
                selectClassname="!font-normal"
              />
            </div>
            <div className="flex items-center justify-between gap-2 border-b border-gray-400">
              <div className="truncate text-sm font-normal text-gray-400">
                {t('vendor-dropoff-card.date-dropoff')}
              </div>
              <div className="flex items-center justify-end">
                <ReactDatePicker
                  calendarStartDay={1}
                  dateFormat={'MM-dd-yyyy'}
                  className="w-full cursor-pointer border-none bg-white p-0 px-2 text-right text-xs text-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
                  selected={parseDateWithoutTimezone(d.date_dropoff)}
                  onChange={(date: any) => {
                    handleUpdate('date_dropoff', convertDateToString(date));
                  }}
                />
                <img src={calendarIcon} alt="calendar icon" />
              </div>
            </div>
            <InputCardField
              id="date-dropoff"
              type="text"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.vehicle-decal')}
              disabled
              selectedValue={d.vehicle_decal_number}
            />
            <div className="flex items-center justify-between gap-2 border-b border-gray-400">
              <div className="truncate text-sm !font-normal text-gray-400">
                {t('vendor-dropoff-card.driver')}
              </div>
              <Dropdown
                options={dropdownOptions?.driverOptions || []}
                selectedValue={d.driver_id || ''}
                placeholder={t('vendor-dropoff-card.driver')}
                onSelectChange={(newValue) => {
                  handleUpdate('driver_id', newValue);
                }}
                selectClassname="!font-normal"
              />
            </div>
          </div>
          <div className="flex w-full items-center text-base font-semibold text-zinc-800">
            {t('vendor-dropoff-card.entry-title')}
          </div>
          <div className="grid w-full grid-cols-1 gap-x-5 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <InputCardField
              id="manifest"
              type="text"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.manifest')}
              disabled
              selectedValue={d.manifest_number}
            />
            <div className="flex items-center justify-between gap-2 border-b border-gray-400">
              <div className="truncate text-sm !font-normal text-gray-400">
                {t('vendor-dropoff-card.measuring-method')}
              </div>
              <Dropdown
                options={MEASURING_MEHTOD_OPTIONS || []}
                selectedValue={d.measuring_method || ''}
                placeholder={t('vendor-dropoff-card.measuring-method')}
                onSelectChange={(newValue) => {
                  handleUpdate('measuring_method', newValue);
                }}
                selectClassname="!font-normal"
              />
            </div>
            <InputCardField
              id="gallons"
              type="number"
              format="number"
              unitGal
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.gallons')}
              selectedValue={d.total_gallons}
              placeholder={d.total_gallons}
              onSelectChange={(val) => {
                setValue('total_gallons', Number(val));
              }}
              onSave={() => {
                handleUpdate('total_gallons', d.total_gallons);
              }}
            />
            <InputCardField
              id="pounds"
              type="number"
              format="number"
              otherUnit="Lb"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.pounds')}
              selectedValue={d.total_pounds}
              placeholder={d.total_pounds}
              onSelectChange={(val) => {
                setValue('total_pounds', Number(val));
              }}
              onSave={() => {
                handleUpdate('total_pounds', d.total_pounds);
              }}
            />
            <InputCardField
              id="pounds"
              type="number"
              format="number"
              otherUnit="%"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.miu')}
              selectedValue={d.miu_percentage}
              placeholder={d.miu_percentage}
              onSelectChange={(val) => {
                setValue('miu_percentage', Number(val));
              }}
              onSave={() => {
                handleUpdate('miu_percentage', d.miu_percentage);
              }}
            />
            <InputCardField
              id="container_volume"
              type="number"
              format="number"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.container-volumne')}
              unitGal
              selectedValue={d.container_volume}
              placeholder={d.container_volume}
              onSelectChange={(val) => {
                setValue('container_volume', Number(val));
              }}
              onSave={() => {
                handleUpdate('container_volume', d.container_volume);
              }}
            />
            <InputCardField
              id="ffa"
              type="number"
              format="number"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.ffa')}
              selectedValue={d.ffa}
              placeholder={d.ffa}
              otherUnit="ppm"
              onSelectChange={(val) => {
                setValue('ffa', Number(val));
              }}
              onSave={() => {
                handleUpdate('ffa', d.ffa);
              }}
              showFlag
              redFlag={d.ffa > 10}
            />
            <InputCardField
              id="sulfur"
              type="number"
              format="number"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.sulfur')}
              selectedValue={d.sulfur}
              placeholder={d.sulfur}
              onSelectChange={(val) => {
                setValue('sulfur', Number(val));
              }}
              onSave={() => {
                handleUpdate('sulfur', d.ffa);
              }}
              otherUnit="ppm"
              showFlag
              redFlag={d.sulfur > 32}
            />
            <div className="flex items-center justify-between gap-2 border-b border-gray-400">
              <div className="truncate text-sm !font-normal text-gray-400">
                {t('vendor-dropoff-card.registered-by')}
              </div>
              <Dropdown
                options={dropdownOptions?.userOptions || []}
                selectedValue={d.registered_by || ''}
                placeholder={t('vendor-dropoff-card.registered-by')}
                onSelectChange={(newValue) => {
                  handleUpdate('registered_by', newValue);
                }}
                selectClassname="!font-normal"
              />
            </div>
            <div className="flex w-full items-center justify-between gap-2 border-b border-gray-400">
              <Toggle
                onSelectChange={(newValue) => {
                  handleUpdate('traceability', newValue);
                }}
                selectedValue={d.traceability}
                placeholder={t('vendor-dropoff-card.traceability')}
                className="w-full"
                placeholderClassName="!text-gray-400"
              />
            </div>
          </div>
          <div className="flex w-full items-center text-base font-semibold text-zinc-800">
            {t('vendor-dropoff-card.after-cleaning')}
          </div>
          <div className="grid w-full grid-cols-1 gap-x-5 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <InputCardField
              id="miu"
              type="number"
              format="number"
              otherUnit="Lb"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.miu')}
              selectedValue={d.miu}
              placeholder={d.miu}
              onSelectChange={(val) => {
                setValue('miu', Number(val));
              }}
              onSave={() => {
                handleUpdate('miu', d.miu);
              }}
            />
            <InputCardField
              id="net_ikg"
              type="number"
              format="number"
              otherUnit="Lb"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.net-ikg')}
              selectedValue={d.net_ikg}
              placeholder={d.net_ikg}
              onSelectChange={(val) => {
                setValue('net_ikg', Number(val));
              }}
              onSave={() => {
                handleUpdate('net_ikg', d.net_ikg);
              }}
            />
          </div>
          <div className="flex w-full items-center text-base font-semibold text-zinc-800">
            {t('vendor-dropoff-card.pricing-title')}
          </div>
          <div className="grid w-full grid-cols-1 gap-x-5 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <InputCardField
              id="price_per_pound"
              type="number"
              format="money"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.price-per-pound')}
              selectedValue={d.price_per_pound}
              placeholder={d.price_per_pound}
              onSelectChange={(val) => {
                setValue('price_per_pound', Number(val));
              }}
              onSave={() => {
                handleUpdate('price_per_pound', d.price_per_pound);
              }}
              maxFractionDigits={3}
            />
            <InputCardField
              id="total_amount"
              type="number"
              format="money"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.total-paid-amount')}
              selectedValue={d.total_amount}
              placeholder={d.total_amount}
              onSelectChange={(val) => {
                setValue('total_amount', Number(val));
              }}
              onSave={() => {
                handleUpdate('total_amount', d.total_amount);
              }}
            />
            <InputCardField
              id="total_balance"
              type="number"
              format="money"
              doNotUseSaveSelfButton
              label={t('vendor-dropoff-card.pending-balance')}
              selectedValue={d.balance}
              placeholder={d.balance}
              disabled
            />
          </div>
          <div className="w-full">
            <div className="flex w-full flex-wrap gap-3">
              {d &&
                d.vendor_payments &&
                d.vendor_payments.length > 0 &&
                d.vendor_payments.map((payment, i) => (
                  <DropoffPaymentCard
                    setValue={(key, value) => {
                      setValue(`vendor_payments.${i}.${key}` as any, value);
                    }}
                    onUpdatePayment={() => refetchSingleDropoff(dropoff.id)}
                    key={payment.id}
                    payment={payment}
                  />
                ))}
              <button
                onClick={handleNewPayment}
                id="addPeople"
                className="box-shadow flex h-auto min-h-[170px]  w-[74px] items-center justify-center rounded-lg"
              >
                <img src={iconAdd} alt="add icon" />
              </button>
            </div>
          </div>
          <div className="flex w-full items-center text-base font-semibold text-zinc-800">
            {t('vendor-dropoff-card.distribution-title')}
          </div>
          <div className="grid w-full grid-cols-1 gap-x-5 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="flex w-full items-center justify-between gap-2 border-b border-gray-400">
              <Toggle
                onSelectChange={(newValue) => {
                  handleUpdate('distribution', newValue);
                }}
                selectedValue={d.distribution}
                placeholder={`${t('vendor-dropoff-card.distribution')} ${
                  d.location.name
                }`}
                className="w-full"
                placeholderClassName="!text-gray-400"
              />
            </div>
          </div>
          <DropdownWithContent
            label={t('routes.route-stop-card-documents')}
            renderComponent={
              <DTDropoffsDocuments
                dropoffId={dropoff.id}
                vendorId={dropoff.vendor_id}
              />
            }
          />
          <div className="flex w-full items-center justify-center">
            {/* Delete service */}
            <div className="m-6 flex justify-center">
              <button
                onClick={handleDeleteDropoff}
                id="removeService"
                className="flex items-center"
              >
                <img src={removeIcon} alt="remove icon" />
                <span className="ml-2 text-xs font-bold text-redish underline">
                  {t('vendor-dropoff-card.delete-dropoff')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropoffCard;
