import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import Loader from '../../components/ui/Loader';
import { tokenStateAtom } from '../../recoil/authState';

const CheckAuth = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
    logout,
  } = useAuth0();

  const setToken = useSetRecoilState(tokenStateAtom);

  const navigate = useNavigate();

  const { t } = useTranslation('common');

  const getToken = (options: any) => {
    getAccessTokenSilently(options)
      .then((res) => {
        if (
          user!.user_roles.includes('Manager') ||
          user!.user_roles.includes('Supervisor') ||
          user!.user_roles.includes('Superadmin') ||
          user!.user_roles.includes('Warehouse Manager') ||
          user!.user_roles.includes('Owner')
        ) {
          setToken(res.access_token);
          navigate('/dashboard');
        }
      })
      .catch(() => {
        navigate('/');
      });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
        },
      });
      return;
    }

    getToken(process.env.REACT_APP_AUTH0_AUDIENCE);
  }, [isLoading, isAuthenticated]);

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (
    !user?.user_roles.includes('Manager') &&
    !user?.user_roles.includes('Owner') &&
    !user?.user_roles.includes('Warehouse Manager') &&
    !user?.user_roles.includes('Superadmin')
  ) {
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center">
        <p>{t('global.not-authorized')}</p>
        <button onClick={() => logout()}>Go Back</button>
      </div>
    );
  }

  return null;
};

export default CheckAuth;
