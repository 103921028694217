export const enum ROLES {
  SUPER_ADMIN = 'Superadmin',
  OWNER = 'Owner',
  SUPERVISOR = 'Supervisor',
  MANAGER = 'Manager',
  DRIVER = 'Driver',
  OPERATOR = 'Operator',
  OPERATOR_DEVICE = 'Operator Device',
  WAREHOUSE_MANAGER = 'Warehouse Manager',
}

export const enum PERMISSIONS {
  READ_ROUTE = 'read:route',
  READ_OWNROUTE = 'read:ownroute',
  UPDATE_OWNROUTE = 'update:ownroute',
  DELETE_ROUTE = 'delete:route',
  UPDATE_ROUTE = 'update:route',
  CREATE_ROUTE = 'create:route',

  DELETE_SERVICE = 'delete:service',
  UPDATE_SERVICE = 'update:service',
  CREATE_SERVICE = 'create:service',

  READ_USER = 'read:user',
  READ_OWNUSER = 'read:ownuser',
  UPDATE_USER = 'update:user',
  DELETE_USER = 'delete:user',
  CREATE_USER = 'create:user',

  UPDATE_TRUCK = 'update:truck',
  READ_TRUCK = 'read:truck',
  DELETE_TRUCK = 'delete:truck',
  CREATE_TRUCK = 'create:truck',

  READ_TRUCKINCIDENT = 'read:truckincident',
  CREATE_TRUCKINCIDENT = 'create:truckincident',
  UPDATE_TRUCKINCIDENT = 'update:truckincident',

  UPDATE_STOP = 'update:stop',
  READ_STOP = 'read:stop',
  CREATE_STOP = 'create:stop',

  UPLOAD_FILE = 'upload:file',

  CREATE_DRIVERRECEIPT = 'create:driverreceipt',
  DELETE_DRIVERRECEIPT = 'delete:driverreceipt',
  UPDATE_DRIVERRECEIPT = 'update:driverreceipt',

  CREATE_NOTES = 'create:notes',
  DELETE_NOTES = 'delete:notes',

  READ_CLIENT = 'read:client',
  CREATE_CLIENT = 'create:client',
  UPDATE_CLIENT = 'update:client',
  DELETE_CLIENT = 'delete:client',

  READ_CLIENTPEOPLE = 'read:clientpeople',
  CREATE_CLIENTPEOPLE = 'create:clientpeople',
  UPDATE_CLIENTPEOPLE = 'update:clientpeople',
  DELETE_CLIENTPEOPLE = 'delete:clientpeople',

  READ_DOCUMENT = 'read:document',
  DELETE_DOCUMENT = 'delete:document',

  READ_INVOICE = 'read:invoice',

  CREATE_TRUCKDRIVER = 'create:truckdriver',

  READ_SETTINGS = 'read:settings',
  UPDATE_SETTINGS = 'update:settings',

  READ_LOCATIONS = 'read:location',
  CREATE_LOCATION = 'create:location',
  UPDATE_LOCATION = 'update:location',
  DELETE_LOCATION = 'delete:location',

  READ_VENDORS = 'read:vendor',
  CREATE_VENDORS = 'create:vendor',
  UPDATE_VENDORS = 'update:vendor',
  DELETE_VENDORS = 'delete:vendor',

  READ_DROPOFFS = 'read:dropoff',
  CREATE_DROPOFFS = 'create:dropoff',
  UPDATE_DROPOFFS = 'update:dropoff',
  DELETE_DROPOFFS = 'delete:dropoff',
}
