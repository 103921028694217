import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import closeIcon from '../../assets/icons/close-square.svg';
import logoutIcon from '../../assets/icons/logout-black.svg';
import iconWarning from '../../assets/icons/warning-red.svg';

interface ModalLogoutProps {
  handleClose: () => void;
}

function ModalLogout({ handleClose }: ModalLogoutProps) {
  const { t } = useTranslation('common');
  const { logout } = useAuth0();

  const onLogout = () => {
    handleClose();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="fixed inset-0 bg-gray-800 opacity-50"
          onClick={handleClose}
        ></div>
        <div className="fixed left-1/2 top-1/2 w-[380px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-[20px] bg-white p-6 shadow-lg md:px-10 md:py-14">
          <button
            onClick={handleClose}
            className="absolute right-2 top-2 m-2 cursor-pointer text-lg text-gray-500"
          >
            <img src={closeIcon} alt="close icon" />
          </button>
          <div className="flex flex-col items-center justify-center gap-2">
            <img src={iconWarning} alt="warning icon" />
            <p className="text-center text-xl font-semibold">
              {t('global.title-logout')}
            </p>
            <p className="mb-4 text-center text-xs">
              {t('global.question-logout')}
            </p>
            <div className="flex gap-4">
              <button
                className="flex items-center gap-2 rounded-md border border-green bg-white px-[10px] py-[6px] text-xs text-green hover:bg-green hover:text-white"
                onClick={handleClose}
              >
                {t('global.cancel')}
              </button>
              <button
                className="button-icon-reverse flex items-center gap-2 rounded-md bg-redish px-[10px] py-[6px] text-xs text-white hover:bg-gold hover:text-blacker"
                onClick={onLogout}
              >
                <img className="h-[20px]" src={logoutIcon} alt="trash icon" />
                {t('global.logout-confirm')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalLogout;
