import { gql } from '@apollo/client';

export const GET_USER_BY_AUTH0_ID = gql`
  query GetUser($auth0_id: String!) {
    getUser(auth0_id: $auth0_id, permissions: true) {
      status
      code
      data {
        id
        name
        phone
        email
        role
        status
        profile_picture
        signature
        createdAt
        updatedAt
        auth0_id
        permissions
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUser($id: String!) {
    getUser(id: $id) {
      status
      code
      data {
        id
        name
        phone
        email
        role
        status
        profile_picture
        signature
        createdAt
        updatedAt
        auth0_id
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers(
    $filterDeleted: Boolean
    $filterName: String
    $filterStatus: [String]
    $filterRole: [String]
    $offset: Int
    $limit: Int
    $orderDirection: String
    $orderBy: String
  ) {
    getUsers(
      orderDirection: $orderDirection
      orderBy: $orderBy
      filterDeleted: $filterDeleted
      filterStatus: $filterStatus
      filterRole: $filterRole
      filterName: $filterName
      offset: $offset
      limit: $limit
    ) {
      status
      code
      count
      error
      data {
        id
        name
        phone
        email
        role
        status
        profile_picture
        signature
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
