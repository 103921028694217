import { useAuth0 } from '@auth0/auth0-react';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalFull from '../../components/Modals/ModalFull';
import { LoaderScreen } from '../../components/ui/LoaderScreen';
import { PERMISSIONS } from '../../config/permissions';
import { usePermissions } from '../../hooks/usePermission';

const CounterScheduled = React.lazy(
  () => import('../../components/ui/dashboard/CounterScheduled'),
);
const CounterCompletedStops = React.lazy(
  () => import('../../components/ui/dashboard/CounterCompletedStops'),
);
const CounterSkippedStops = React.lazy(
  () => import('../../components/ui/dashboard/CounterSkippedStops'),
);

const DTScheduled = React.lazy(
  () => import('../../components/ui/dashboard/DTScheduled'),
);
const DTSuggested = React.lazy(
  () => import('../../components/ui/dashboard/DTSuggested'),
);
const DTReview = React.lazy(
  () => import('../../components/ui/dashboard/DTReview'),
);
const DTWarning = React.lazy(
  () => import('../../components/ui/dashboard/DTWarning'),
);

const DashboardView = () => {
  const { t } = useTranslation('common');
  const { checkSinglePermission } = usePermissions();
  const { user } = useAuth0();
  const userRoles = user?.user_roles;
  const supervisor = userRoles?.includes('Supervisor');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section id="WelcomeText">
        <h1 className="text-3xl font-bold">
          {t('dashboard.title-h1')} {user ? user.name : ''}
        </h1>
        {checkSinglePermission(PERMISSIONS.READ_ROUTE) && (
          <div className="my-6 flex min-h-[115px] w-[461px] flex-col gap-5 sm:flex-row">
            <Suspense>
              <CounterScheduled />
            </Suspense>
            <Suspense>
              <CounterCompletedStops />
            </Suspense>
            <Suspense>
              <CounterSkippedStops />
            </Suspense>
          </div>
        )}
      </section>
      <section>
        {checkSinglePermission(PERMISSIONS.READ_ROUTE) && (
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div className="flex flex-col">
              <h2 className="my-1 text-lg font-medium">
                {t('dashboard.table-scheduled-title')}
              </h2>
              <Suspense fallback={<LoaderScreen />}>
                <DTScheduled />
              </Suspense>
            </div>
            {!supervisor && (
              <div className="flex flex-col">
                <h2 className="my-1 text-lg font-medium">
                  {t('dashboard.table-suggested-title')}
                </h2>
                <Suspense fallback={<LoaderScreen />}>
                  <DTSuggested toggleModal={toggleModal} />
                </Suspense>
              </div>
            )}
            <div className="flex flex-col">
              <h2 className="my-1 text-lg font-medium">
                {t('dashboard.table-warning-title')}
              </h2>
              <Suspense fallback={<LoaderScreen />}>
                <DTWarning />
              </Suspense>
            </div>
            <div className="flex flex-col">
              <h2 className="my-1 text-lg font-medium">
                {t('dashboard.table-review-title')}
              </h2>
              <Suspense fallback={<LoaderScreen />}>
                <DTReview />
              </Suspense>
            </div>
          </div>
        )}
        <ModalFull toggleModal={toggleModal} isOpen={isOpen} />
      </section>
    </>
  );
};

export default DashboardView;
