export const DASHBOARD_TABLES_MAX_RESULTS = 5;
export const CLIENTS_TABLES_MAX_RESULTS = 10;
export const VENDORS_TABLES_MAX_RESULTS = 10;
export const VENDORS_TRANSACTIONS_TABLES_MAX_RESULTS = 5;
export const LAST_ROUTES_TABLE_MAX_RESULTS = 5;
export const LAST_INCIDENTS_TABLE_MAX_RESULTS = 5;
export const TRUCK_DOCUMENTS_TABLE_MAX_RESULTS = 5;
export const ROUTE_TABLES_MAX_RESULTS = 10;
export const TRUCKS_TABLES_MAX_RESULTS = 10;
export const USERS_TABLES_MAX_RESULTS = 10;
export const INVOICES_TABLES_MAX_RESULTS = 10;
export const WITHDRAW_TABLES_MAX_RESULTS = 5;

export const TIMELINE_MAX_RESULTS = 5;
export const DROPOFFS_MAX_RESULTS = 10;
export const MAX_NUMBER_OF_TIMELINES = 100;

export const NOTES_MAX_RESULTS = 5;
export const MAX_NUMBER_OF_NOTES = 100;

export const OIL_DISPOSAL_SERVICE_NAME = 'Oil Disposal';
export const HOOD_CLEANING_SERVICE_NAME = 'Hood Cleaning';
export const GREASE_TRAP_SERVICE_NAME = 'Grease Trap Cleaning';
export const FAT_BONES_SERVICE_NAME = 'Meat, Fat & Bone';

export const USER_ROLES = [
  { value: 'Driver', label: 'Driver' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Supervisor', label: 'Supervisor' },
  { value: 'Operator', label: 'Operator' },
  { value: 'Operator Device', label: 'Operator Device' },
  { value: 'Warehouse Manager', label: 'Warehouse Manager' },
  // { value: 'Superadmin', label: 'Superadmin' },
];

export const statusClientOptions = [
  { value: 'Active', label: 'ACTIVE', color: '#00870D' },
  { value: 'Inactive', label: 'INACTIVE', color: '#646464' },
  { value: 'Payment pending', label: 'PAYMENT PENDING', color: '#D30000' },
];

export const statusVendorsOptions = [
  { value: 'Active', label: 'ACTIVE', color: '#00870D' },
  { value: 'Inactive', label: 'INACTIVE', color: '#646464' },
];

export const statusTruckOptions = [
  { value: 'Active', label: 'ACTIVE', color: '#00870D' },
  { value: 'Pending diagnosis', label: 'PENDING DIAGNOSIS', color: '#4C80CD' },
  { value: 'Missing Tools', label: 'Missing Tools', color: '#0000ff' },
  { value: 'Retired', label: 'RETIRED', color: '#646464' },
  { value: 'Under repair', label: 'UNDER REPAIR', color: '#F9D03E' },
  {
    value: 'Waiting for Repairs',
    label: 'WAITING FOR REPAIRS',
    color: '#FF932F',
  },
];

export const statusWorkerOptions = [
  { value: 'Active', label: 'ACTIVE', color: '#00870D' },
  { value: 'Absent', label: 'ABSENT', color: '#F9D03E' },
];

export const statusTruckIncidentOptions = [
  { value: 'No Repair Needed', label: 'NO REPAIR NEEDED', color: '#646464' },
  {
    value: 'Waiting for Repairs',
    label: 'WAITING FOR REPAIRS',
    color: '#4C80CD',
  },
  { value: 'Totaled', label: 'TOTALED', color: '#D30000' },
  { value: 'Repairing', label: 'REPAIRING', color: '#F9D03E' },
  { value: 'Fixed', label: 'FIXED', color: '#00870D' },
  { value: 'Missing', label: 'MISSING', color: '#0000ff' },
  { value: 'Replaced', label: 'Replaced', color: '#00870D' },
];

export const serviceClientOptions = [
  { value: OIL_DISPOSAL_SERVICE_NAME, label: OIL_DISPOSAL_SERVICE_NAME },
  { value: HOOD_CLEANING_SERVICE_NAME, label: HOOD_CLEANING_SERVICE_NAME },
  { value: GREASE_TRAP_SERVICE_NAME, label: GREASE_TRAP_SERVICE_NAME },
  { value: FAT_BONES_SERVICE_NAME, label: FAT_BONES_SERVICE_NAME },
];

export const serviceFrequencyOptions = [
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Biweekly', label: 'Biweekly' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Quarterly', label: 'Quarterly' },
  { value: 'Semiannual', label: 'Semiannual' },
];

export const serviceZoneOptions = [
  { value: 'Zone A', label: 'Zone A' },
  { value: 'Zone B', label: 'Zone B' },
  { value: 'Zone C', label: 'Zone C' },
  { value: 'Zone D', label: 'Zone D' },
  { value: 'Zone E', label: 'Zone E' },
];

export const documentTypeOptions = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Manifesto ', label: 'Manifesto ' },
  { value: 'Driver Receipt', label: 'Driver Receipt' },
  { value: 'Other', label: 'Other' },
];

export const stopOptions = [
  { value: 'Pending', label: 'Pending', color: '#9242D1' },
  { value: 'Skipped', label: 'Skipped', color: '#D30000' },
  { value: 'In Review', label: 'In Review', color: '#4C80CD' },
  { value: 'Waiting Payments', label: 'Waiting Payments', color: '#FF932F' },
  { value: 'Completed', label: 'Completed', color: '#00870D' },
];

export const routeOptions = [
  { value: 'Draft', label: 'Draft', color: '#646464' },
  { value: 'Scheduled', label: 'Scheduled', color: '#9242D1' },
  { value: 'Canceled', label: 'Canceled', color: '#D30000' },
  { value: 'On Going', label: 'On Going', color: '#FFFF00' },
  { value: 'Warning', label: 'Warning', color: '#FFA500' },
  { value: 'In Review', label: 'In Review', color: '#4C80CD' },
  { value: 'Waiting Payments', label: 'Waiting Payments', color: '#FF932F' },
  { value: 'Completed', label: 'Completed', color: '#00870D' },
];

export const documentOptions = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Manifesto', label: 'Manifesto' },
  { value: 'Driver Receipt', label: 'Driver Receipt' },
  { value: 'Document', label: 'Document' },
  { value: 'Other', label: 'Other' },
];

export const paymentOptions = [
  { value: 'Cash', label: 'Cash' },
  { value: 'Estimate', label: 'Estimate' },
  { value: 'Warranty', label: 'Warranty' },
  { value: 'Contract', label: 'Contract' },
  { value: 'Charge', label: 'Charge' },
  { value: 'C.O.D.', label: 'C.O.D.' },
];

export const paymentStatusOptions = [
  { value: 'Paid', label: 'Paid' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Overdue', label: 'Overdue' },
  { value: 'Unpaid', label: 'Unpaid' },
  { value: 'In Review', label: 'In Review' },
];

export const paidStatusOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const receiptTypeOptions = [
  { value: 'Food', label: 'Food' },
  { value: 'Fuel', label: 'Fuel' },
  { value: 'Maintenance', label: 'Maintenance' },
  { value: 'Receipt', label: 'Receipt' },
  { value: 'Other', label: 'Other' },
];

export const truckServiceOptions = [
  { value: 'oil_service', label: OIL_DISPOSAL_SERVICE_NAME },
  { value: 'hood_service', label: HOOD_CLEANING_SERVICE_NAME },
  { value: 'grease_service', label: GREASE_TRAP_SERVICE_NAME },
];

export const truckMakeOptions = [
  { value: 'Ford', label: 'Ford' },
  { value: 'Chevrolet', label: 'Chevrolet' },
  { value: 'GMC', label: 'GMC' },
  { value: 'Dodge', label: 'Dodge' },
  { value: 'Freightliner', label: 'Freightliner' },
  { value: 'International', label: 'International' },
  { value: 'Kenworth', label: 'Kenworth' },
  { value: 'Mack', label: 'Mack' },
  { value: 'Peterbilt', label: 'Peterbilt' },
  { value: 'Sterling', label: 'Sterling' },
  { value: 'Volvo', label: 'Volvo' },
  { value: 'Western Star', label: 'Western Star' },
  { value: 'Other', label: 'Other' },
];

export const truckModelOptions = [
  { value: 'Truck A', label: 'Truck A' },
  { value: 'Truck B', label: 'Truck B' },
  { value: 'Truck C', label: 'Truck C' },
  { value: 'Truck D', label: 'Truck D' },
  { value: 'Truck E', label: 'Truck E' },
  { value: 'Truck F', label: 'Truck F' },
  { value: 'Truck G', label: 'Truck G' },
  { value: 'Truck H', label: 'Truck H' },
  { value: 'Truck I', label: 'Truck I' },
];

export const damageCategoryOptions = [
  { value: 'Mechanical Failure', label: 'Mechanical Failure' },
  { value: 'Tires Damage', label: 'Tires Damage' },
  { value: 'Bodywork Damage', label: 'Bodywork Damage' },
  { value: 'Container Damage', label: 'Container Damage' },
];

export const damageGradeOptions = [
  { value: 'High', label: 'High', color: '#00870D' },
  { value: 'Medium', label: 'Medium', color: '#F9D03E' },
  { value: 'Low', label: 'Low', color: '#D30000' },
];

export const tireStatusOptions = [
  { value: 'Good', label: 'Good', color: '#00870D' },
  { value: 'Bad', label: 'Bad', color: '#D30000' },
];

export const WEEKDAYS_SHORTENED: Record<string, string> = {
  Monday: 'Mn',
  Tuesday: 'Tu',
  Wednesday: 'Wd',
  Thursday: 'Th',
  Friday: 'Fr',
  Saturday: 'Sa',
  Sunday: 'Su',
};

export const availableDaysOptions = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];

export const DROPOFF_STATUS = [
  { value: 'In Review', label: 'In Review', color: '#4C80CD' },
  { value: 'Pending Payments', label: 'Pending Payments', color: '#FF932F' },
  { value: 'Completed', label: 'Completed', color: '#00870D' },
];

export const TRUCK_NOTIFICATIONS = [
  'missing-equipment-starting',
  'missing-equipment',
  'excesive-equipment',
  'possible-error-equipment',
  'anomaly-equipment',
  'truck-licensing',
];

export const LICENSING_NOTIFICATIONS = ['settings-licensing'];

export const ROUTE_NOTIFICATIONS = ['route-confirmed'];

export const MEASURING_MEHTOD_ENUM = ['scale', 'meter'];
export const MEASURING_MEHTOD_OPTIONS = [
  {
    label: 'Scale',
    value: 'scale',
  },
  {
    label: 'Meter',
    value: 'meter',
  },
];

export const PAYMENT_TYPE_OPTIONS = [
  {
    label: 'Checks',
    value: 'Checks',
  },
  {
    label: 'ACH',
    value: 'ACH',
  },
  {
    label: 'Zelle',
    value: 'Zelle',
  },
  {
    label: 'Cash',
    value: 'Cash',
  },
  {
    label: 'Loan Credit',
    value: 'Loan Credit',
  },
];

export const RANGE_DATE_DROPDOWN_OPTIONS = [
  'today',
  'last_7',
  'last_30',
  'personalized',
];

export const DAYJS_ONLY_DATE_FORMAT = 'YYYY-MM-DD';

export const MIN_MAX_GALLONS = [0, 7000];
export const MIN_MAX_MIU = [0, 70];
export const MIN_MAX_GALLON_PRICE = [0, 1];
export const MIN_MAX_TOTAL_PAID = [0, 100000];
export const MIN_MAX_FFA = [0, 30];
export const MIN_MAX_SULFUR = [0, 100];

export const STEP_GALLONS = 100;
export const STEP_MIU = 0.1;
export const STEP_GALLON_PRICE = 0.01;
export const STEP_TOTAL_PAID = 10000;
export const STEP_FFA = 1;
export const STEP_SULFUR = 1;
