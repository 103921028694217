/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from 'react';

import editIcon from '../../../../assets/icons/edit.svg';
import flagIcon from '../../../../assets/icons/input-flag.svg';
import redFlagIcon from '../../../../assets/icons/input-flag-red.svg';
import saveIcon from '../../../../assets/icons/save-small.svg';
import {
  formatNumber,
  formatNumberMoney,
} from '../../../../utils/numberFormatter';

type Props = {
  label?: string;
  selectedValue?: string | number | null;
  onSelectChange?: (value: string | number) => void;
  placeholder?: string | undefined | number;
  doNotUseSaveSelfButton?: boolean;
  onSave?: () => void;
  type: string;
  register?: any;
  registerName?: string;
  validationSchema?: any;
  errors?: any;
  id: string;
  unitGal?: boolean;
  format?: 'number' | 'money';
  unitMoney?: boolean;
  disabled?: boolean;
  otherUnit?: string;
  showFlag?: boolean;
  redFlag?: boolean;
  maxFractionDigits?: number;
};

const InputCardField = ({
  selectedValue,
  onSelectChange,
  label,
  placeholder,
  onSave,
  type,
  register,
  registerName,
  validationSchema,
  id,
  unitGal,
  format,
  disabled,
  otherUnit,
  showFlag,
  redFlag,
  maxFractionDigits = 2,
}: Props) => {
  const [inputHovered, setInputHovered] = useState<boolean>(false);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | number>(
    selectedValue || '',
  );
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [originalPlaceholder, setOriginalPlaceholder] = useState<
    string | number
  >(placeholder || '');
  const [inputKey, setInputKey] = useState(Math.random());
  const [formattedInputValue, setFormattedInputValue] = useState('');
  // const [savedValue, setSavedValue] = useState(originalPlaceholder);

  useEffect(() => {
    if (typeof placeholder === 'number') {
      let formattedPlaceholder;
      switch (format) {
        case 'money':
          formattedPlaceholder = formatNumberMoney(
            placeholder,
            maxFractionDigits,
          );
          break;
        case 'number':
          formattedPlaceholder = formatNumber(placeholder);
          break;
        default:
          formattedPlaceholder = placeholder;
      }
      setOriginalPlaceholder(formattedPlaceholder);
    } else {
      setOriginalPlaceholder(placeholder || '');
    }
  }, [placeholder, format]);

  useEffect(() => {
    if (selectedValue && selectedValue !== inputValue) {
      setInputValue(selectedValue);
    }
    setIsSaved(false);
  }, [selectedValue, inputValue, placeholder]);

  useEffect(() => {
    const savedNumber = parseFloat(inputValue.toString().replace(/,/g, ''));
    if (!Number.isNaN(savedNumber)) {
      if (format === 'number') {
        setFormattedInputValue(formatNumber(savedNumber));
      } else if (format === 'money') {
        setFormattedInputValue(
          formatNumberMoney(savedNumber, maxFractionDigits),
        );
      }
    }
  }, [inputValue, format, placeholder]);

  const resetValues = (currentValue: string | number) => {
    if (type === 'text') {
      setOriginalPlaceholder(currentValue);
      // setSavedValue(currentValue);
      setInputValue(currentValue);
    } else {
      const savedNumber = parseFloat(
        lastInputValueRef.current.toString().replace(/,/g, ''),
      );
      if (!Number.isNaN(savedNumber)) {
        let savedValueLocal = savedNumber.toString();
        if (format === 'number') {
          savedValueLocal = formatNumber(savedValueLocal);
        } else if (format === 'money') {
          savedValueLocal = formatNumberMoney(
            savedValueLocal,
            maxFractionDigits,
          );
        }

        setOriginalPlaceholder(savedValueLocal);
        // setSavedValue(savedValueLocal);
        setInputValue(savedValueLocal);
      }
    }
    setIsSaved(false);
  };

  const lastInputValueRef = useRef('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (type === 'number' && newValue.replace(/[^0-9]/g, '').length > 8) {
      return;
    }
    if (type === 'text' && newValue.length > 20) {
      return;
    }

    setInputValue(newValue);
    lastInputValueRef.current = newValue;
    if (onSelectChange) onSelectChange(newValue);
  };

  const handleSave = () => {
    if (onSave) {
      setInputFocused(false);
      onSave();
    }
    const currentValue = inputValue;
    setTimeout(() => {
      if (type === 'text') {
        setOriginalPlaceholder(currentValue);
        // setSavedValue(currentValue);
        setInputValue(currentValue);
      } else {
        const savedNumber = parseFloat(
          lastInputValueRef.current.toString().replace(/,/g, ''),
        );
        if (!Number.isNaN(savedNumber)) {
          let savedValueLocal = savedNumber.toString();
          if (format === 'number') {
            savedValueLocal = formatNumber(savedValueLocal);
          } else if (format === 'money') {
            savedValueLocal = formatNumberMoney(
              savedValueLocal,
              maxFractionDigits,
            );
          }

          setOriginalPlaceholder(savedValueLocal);
          // setSavedValue(savedValueLocal);
          setInputValue(savedValueLocal);
        }
      }
      setIsSaved(true);
      setInputKey(Math.random());
    }, 50);
    resetValues(currentValue);
  };

  return (
    <div>
      <div className="flex w-full items-center justify-around gap-2 border-b border-gray-400">
        <div className="w-full truncate text-xs font-normal text-gray-400">
          {label}
        </div>
        <div
          onMouseEnter={() => setInputHovered(true)}
          onMouseLeave={() => setInputHovered(false)}
          className="flex w-fit flex-row items-center justify-end gap-1 text-xs font-normal text-zinc-800"
        >
          {inputHovered && !disabled && !inputFocused && (
            <img src={editIcon} alt="edit icon" />
          )}
          <input
            id={id}
            key={inputKey}
            ref={inputRef}
            value={
              inputFocused
                ? inputValue
                : (type === 'text' ? inputValue : formattedInputValue) || ''
            }
            onChange={handleInputChange}
            onFocus={() => {
              setInputFocused(true);
              setInputValue('');
            }}
            disabled={disabled}
            // onBlur={handleBlur}
            type={type}
            maxLength={type === 'number' ? 8 : 20}
            className={`w-full border-none p-0 text-right text-xs  placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0`}
            placeholder={isSaved ? formattedInputValue : originalPlaceholder}
            {...(register &&
              registerName &&
              register(registerName, validationSchema))}
          />
          {unitGal && (
            <div onClick={() => inputRef.current?.focus()}>
              <p>Gal</p>
            </div>
          )}
          {otherUnit && (
            <div onClick={() => inputRef.current?.focus()}>
              <p>{otherUnit}</p>
            </div>
          )}
          {inputFocused && (
            <button type="button" onMouseDown={handleSave}>
              <img src={saveIcon} className="h-[20px]" alt="save icon" />
            </button>
          )}
          {showFlag && (
            <div
              className="flex-none"
              onClick={() => inputRef.current?.focus()}
            >
              <img src={redFlag ? redFlagIcon : flagIcon} alt="edit icon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputCardField;
