import { useRecoilValue } from 'recoil';

import type { PERMISSIONS, ROLES } from '../config/permissions';
import { userPermissionsAtom, userRolesAtom } from '../recoil/userData';

export const usePermissions = () => {
  const userAuthority = useRecoilValue(userRolesAtom) as string[];
  const userPermissions = useRecoilValue(userPermissionsAtom) as string[];

  const hasUserAuthority = () => {
    return userAuthority.length > 0;
  };

  const checkRole = (role: ROLES) => {
    return userAuthority.includes(role.valueOf());
  };

  const checkSinglePermission = (permission: PERMISSIONS) => {
    return userPermissions.includes(permission.valueOf());
  };

  const checkAllPermissions = (permissions: PERMISSIONS[]) => {
    return permissions.every((p) => userPermissions.includes(p.valueOf()));
  };

  const checkSomePermissions = (permissions: PERMISSIONS[]) => {
    return permissions.some((p) => userPermissions.includes(p.valueOf()));
  };

  return {
    hasUserAuthority,
    checkRole,
    checkSinglePermission,
    checkAllPermissions,
    checkSomePermissions,
  };
};
